<template>
  <div class="login__wrapper flex_con">
    <el-form :model="loginForm" :rules="loginrules" ref="loginForm">
      <el-form-item prop="account">
        <el-input
          placeholder="请输入账号"
          v-model="loginForm.account"
        >
        </el-input>
        <ch-icon name="login-user"></ch-icon>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          placeholder="请输入密码"
          v-model="loginForm.password"
          autocomplete="off"
          show-password>
        </el-input>
        <ch-icon name="login-pass"></ch-icon>
      </el-form-item>

      <el-form-item prop="code" class="qrcode">
        <el-input
          placeholder="验证码"
          v-model="loginForm.code"
          autocomplete="new-password"
          >
        </el-input>
        <ch-icon name="login-dun"></ch-icon>
        <div class="qrcode-img-wrapper flex_con" @click="getQRCode">
          <el-image
            class="qrcode-img"
            style="width: 100%; height: 100%"
            :src="crCodeUrl"
            fit="fill"></el-image>
        </div>
        <span class="changeCode" @click="getQRCode">换一张</span>
      </el-form-item>

      <div class="login-text flex_space_between">
        <span class="register" @click="openRegister">商户入驻</span>
        <span class="pw" @click="handleChangePassword">忘记密码</span>
      </div>

      <el-button
        :loading="loading"
        size="max" class="flex_con"
        @click="handleLogin('loginForm')"
      >立即登录</el-button>

    </el-form>

  </div>
</template>

<script>
import request from "@/utils/request";
import {mapMutations} from "vuex"
export default {
  props:["type"],
  data() {

    return {
      loginForm: {
        account: '',
        password: '',
        code: '',
      },
      codeId: null,
      loading: false,
      crCodeUrl: '', // 验证码图片
      loginrules: {
        account: [{ required: true, message: "请输入工号" }],
        password: [{ required: true, message: "请输入密码" }],
        code: [{ required: true, message: "请输入验证码" }],
      },
      timer: null,
    }
  },
  mounted() {
    let that = this
    document.onkeydown = function (e) {
      e = window.event || e
      //保证是在登录页面发出的enter事件
      if ((that.$route.path === '/login'||that.$route.path === '/') && (e.code === 'Enter' || e.code === 'enter')) {
        //调用登录函数
        that.handleLogin('loginForm');
      }
    }
    this.getQRCode()
  },
  created() {
    this.timer = setInterval(() => {
      this.getQRCode()
    }, 1000*60*3)
  },
  beforeDestroy() {
    document.onkeydown = null
    clearInterval(this.timer);
  },
  computed:{
  },
  methods: {
    ...mapMutations({
      savePopup:'savePopup'
    }),
    // 切换忘记密码
    handleChangePassword() {
      this.$emit('update:type', 2)
    },
    openRegister() {
      this.$router.push({ path: "/registerModal" });
    },
    // 登录
    handleLogin(refName) {
      this.loading = true;
      const params = {
        password: this.loginForm.password,
        username: this.loginForm.account,
        code: this.loginForm.code,
        codeKey: this.codeId
      }
      this.$refs[refName].validate((valid) => {
        if (valid) {
          let self = this;
          self.$curl.post("/hm/login/account", params).then(
            async res => {
              if (!res.data.code) {
                localStorage.setItem("token", "Bearer " + res.data.access_token);
                localStorage.setItem("refresh_token", res.data.refresh_token);
                localStorage.setItem("user_id", res.data.user_id);
                await this.$router.push({path: "/classicIndex"});
                await self.$curl.get("/hm/homePage/noticePopup").then((res) => {
                  this.savePopup(res.data)
                })
              }else if (res.data.code && res.data.code !== 200) {
                this.loading = false;
                this.getQRCode()
                this.$message.error(res.data.msg);
              }

            },
          ).catch(() => {
            this.getQRCode()
            this.loading = false;
          })
        } else {
          this.loading = false;
          return false;
        }
      });
    },

    // 请求验证码
    getQRCode() {
      request.get('/hm/login/code', {
        responseType: 'blob',
      }).then(res => {
          let blob = new Blob([res.data],{type: "image/png"});
          this.crCodeUrl = window.URL.createObjectURL(blob);
          this.codeId = res.headers.codeid
      }).catch(() => {
        setTimeout(()=> {
          this.getQRCode()
        },2000)

      })
    },
  }
}
</script>

<style lang="scss" scoped>
.login__wrapper {
  display: inline-block;

  ::v-deep .el-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // 验证码
    .qrcode {
      .el-form-item__content {
        display: flex;
        position: relative;
      }
      .el-input, .el-input__inner {
        width: calc(10vw - 38px) ;
        min-width: 120px;
      }
      .qrcode-img-wrapper {
        background-color: #FFFFFF;
        border: 1px solid rgb(220, 223, 230);
        border-radius: 200px;
        position: absolute;
        right: 0;
        width: 7vw;
        min-width: 100px;
        height: 5vh;
        top: 50%;
        transform: translate(-38px, -50%);

        .qrcode-img {
          border-radius: 200px;
        }
      }
      .changeCode {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        color: #3e7eff;
      }
    }

    & .el-form-item {
      width: 100%;
      position: relative;
      padding-bottom: 0.5vh;
      margin-bottom: 2.5vh;
      .CH_icon {
        font-size: 16px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-20%, -50%);
      }

      &.lock-wrapper {
        margin-bottom: 5px;
      }

      .lock {
        width: 16vw;
      }
    }

    & .el-input__icon {
      transform: translate(-6px, 2px);
    }

    & .el-input__inner {
      box-sizing: border-box;
      padding: 1px 30px 0 42px;
      width: 18vw;
      min-width: 260px;
      height: 5vh;
      color: #333;
      border-radius:200px;
      background-color: #F5F6F9;
      font-size: 15px;
      &::placeholder{
        transform: translateY(1px);
        font-size: 15px;
        color: #999999;
      }
    }

    & .el-form-item__error {
      margin: 2px 0 0 44px;
      min-width: 260px;
    }

    & .login-text {
      transform: translateY(0px);
      font-size: 14px;
      width: 16vw;
      min-width: 250px;
      .register {
        color: #3E7EFF;
        cursor: pointer;
      }
      .pw {
        color: #999999;
        cursor: pointer;
      }
    }

    & .el-button {
      font-size: 15px;
      width: 18vw;
      height: 5vh;
      min-width: 260px;
      border-radius: 200px;
      background-color: #3E7EFFFF;
      color: #FFFFFF;
      margin-top: 2vh;
    }
  }
}
</style>


